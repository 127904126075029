<template>
  <div>
    <Breadcrumbs title="Configurations" main="Zoom"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" class="m-t-10">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                      <b-button v-show="checkUserPermissions(user,['create_zoom_configs'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Config</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(action)="row" class="text-right">
                   <b-button v-show="checkUserPermissions(user,['update_zoom_configs'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
<!--                    <b-button v-show="checkUserPermissions(user,['delete_classes'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>-->
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>



              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" size="xl" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">

                <div class="row">
                  <div class="col-6">
                    <b-form-group label="Account Name *">
                      <b-input type="text" class="btn-square" v-model="formModal.name" required></b-input>
                    </b-form-group>
                  </div>
                  <div class="col-6" v-if="formModal.id">
                    <b-form-group label="Id">
                      <b-input v-if="form_modal_id === 'form_modal_create'" v-model="formModal.id"></b-input>
                      <b-input v-else v-model="formModal.id" readonly></b-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <b-form-group label="App Name">
                      <b-input type="text" class="btn-square" v-model="formModal.app_name"></b-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <b-form-group label="API Key *">
                      <b-input type="text" class="btn-square" v-model="formModal.api_key" required></b-input>
                    </b-form-group>
                  </div>

                  <div class="col-6">
                    <b-form-group label="Client Secret *">
                      <b-input type="text" class="btn-square" v-model="formModal.client_secret" required></b-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <b-form-group label="SDK Key">
                      <b-input type="text" class="btn-square" v-model="formModal.sdk_key"></b-input>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group label="SDK Secret">
                      <b-input type="text" class="btn-square" v-model="formModal.sdk_secret"></b-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <b-form-group label="JWT Token">
                      <b-input type="text" class="btn-square" v-model="formModal.jwt_token"></b-input>
                    </b-form-group>
                  </div>
                </div>

              </b-modal>
              <!-- End Create Modal -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapGetters, mapState } from 'vuex'
import CRUD_API from '@/services/crud'
export default {
  name: "zoom-configurations",
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      institutes: 'select/institutes',
      streams: 'select/streams',
    })
  },
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    this.$store.dispatch('select/fetchRecords', { id: 'streams' })
    this.subjects = []
    this.teachers = []
  },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      subjects: [],
      teachers: [],
      classrooms: [],
      classTypes: [],
      paymentTypes: [],
      api_base: '/api/backend/zoom-account-configs',
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'app_name', label: 'App Name', sortable: true },
        { key: 'client_secret', label: 'Client Secret', sortable: true },
        { key: 'api_key', label: 'API Key', sortable: true },
        { key: 'jwt_token', label: 'JWT Token', sortable: true },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      formModal: {
        id: null,
        index: null,
        name: null,
        app_name: null,
        api_key: null,
        client_secret: null,
        sdk_key: null,
        sdk_secret: null,
        jwt_token: null,
      },
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
        }
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        //this.$refs.table.refresh()
      })
    },

    clearFormModals() { this.selected = []; },
    // Create Record Modal
    showCreateModal(button) {
      this.subjects = []
      this.teachers = []
      this.formModal.index = null
      this.formModal.id = null
      this.formModal.name = null
      this.formModal.app_name = null
      this.formModal.api_key = null
      this.formModal.client_secret = null
      this.formModal.sdk_key = null
      this.formModal.sdk_secret = null
      this.formModal.jwt_token = null
      this.form_modal_title = "New Configuration"
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.formModal.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      this.$root.$emit('bv::show::modal', 'form_modal', false)
      if(this.formModal.name.length < 3 ||
          this.formModal.client_secret.length < 3 ||
          this.formModal.api_key.length < 3 ||
          this.formModal.jwt_token.length < 3 ||
          this.formModal.app_name.length < 3
      ) return;
      CRUD_API.create(this.api_base, this.formModal)
          .then(() => {
            this.$toasted.success("Configuration added successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while saving data")
            this.$toasted.error(err.response.data.message)
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      this.formModal.index = index
      this.formModal.id = item.id
      this.formModal.name = item.name
      this.formModal.app_name = item.app_name
      this.formModal.api_key = item.api_key
      this.formModal.client_secret = item.client_secret
      this.formModal.sdk_key = item.sdk_key
      this.formModal.sdk_secret = item.sdk_secret
      this.formModal.jwt_token = item.jwt_token
      this.form_modal_title = "Edit Configuration "
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.formModal.id, this.formModal)
          .then(() => {
            this.$toasted.success("Configuration updated successfully")
          })
          .catch((err) => {
            console.log('error', err.response.data.data)
            this.$toasted.error(err.response.data.message)
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.delete(this.api_base, this.selected.id)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Configuration removed successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          })
          .finally(() => {
            this.fetchData()
          })
    },


    instituteChanged(){
      // this.formModal.classroom_id = null
      // this.classrooms = []
      this.formModal.stream_id = null
      this.subjects = [];
      this.formModal.subject_id = null
      this.teachers = [];
      this.formModal.teacher_id = null

      CRUD_API.index('/api/backend/select/subjects', {
        params: {
          institute_id: this.formModal.institute_id
        }
      }).then((resp) => {
        this.subjects = resp.data.data
      })
      CRUD_API.index('/api/backend/select/classrooms', {
        params: {institute_id: this.formModal.institute_id}
      }).then((resp) => {
        this.classrooms = resp.data.data
      })
    },
    streamChanged(){
      this.subjects = [];
      this.subject_id = null
      this.teachers = [];
      this.teacher_id = null
      CRUD_API.index('/api/backend/select/subjects', {
        params: { stream_id: this.stream_id }
      }).then((resp) => {
        this.subjects = resp.data.data
      })
    },
    subjectChanged() {
      this.teachers = [];
      this.teacher_id = null;
      CRUD_API.index('/api/backend/select/teachers', {
        params: { subject_id: this.formModal.subject_id }
      }).then((resp) => {
        this.teachers = resp.data.data
      })
    },


    showApproveModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'approve_modal', button)
    },
    showDisapproveModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'disapprove_modal', button)
    },
    submitApproveModal() {
      this.submitStatusModal(0)
    },
    submitDisapproveModal() {
      this.submitStatusModal(this.selected.status)
    },
    async submitStatusModal(status){
      await CRUD_API.create(this.api_base + '/approve', {
        class_detail_id: this.selected.id,
        status: status
      }).then((resp) => {
        if (resp.success) {
          this.$toasted.success(resp.data.message)
        } else {
          this.$toasted.error(resp.data.message)
        }
      }).catch((err) => {
        console.log(err)
        this.$toasted.error(err.data.message)
      }).finally(() => {
        this.fetchData()
      })
    },

  }
}
</script>

<style scoped>

</style>
